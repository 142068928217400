<template>
	<div class="admin-container">
		<Head />
		<div class="admin-main flex">
			<div class="admin-aside admin-media">
				<OrderNav></OrderNav>
			</div>
			<div class="admin-col whole_000 flex_bd">
				<div class="order_box">
					<div class="order-top whole_000">
						<div class="or-top-b">{{$t('adTxt')}}</div>
					</div>
					<div class="order-top order-body whole_fff">
						<div class="or-bd-top flex flex-ac flex-zEnd">
							<div class="or_tips whole-hui ellipsis">【{{$t('czTxt9')}}】：{{$t('adTxt2')}}</div>
							<div class="acc-in-r acc-btn">
								<el-button  type="primary" class="btn" @click="pushAd">{{$t('cr1btn')}}</el-button>
							</div>
						</div>
						<div class="order-table">
							<el-table :data="tableData" style="width: 100%">
								<el-table-column prop="id" :label="$t('adth1')">
								</el-table-column>
								<el-table-column prop="advertiseType" :label="$t('adth2')">
									<template slot-scope="scope">
										<span v-if="scope.row.advertiseType == 1">{{$t('radio')}}</span>
										<span v-if="scope.row.advertiseType == 0">{{$t('radio2')}}</span>
									</template>
								</el-table-column>
								<el-table-column :label="$t('adth3')">
									<template slot-scope="scope">
										<span>{{scope.row.minLimit}}-{{scope.row.maxLimit}}</span>
									</template>
								</el-table-column>
								<el-table-column prop="remainAmount" :label="$t('adth4')">
								</el-table-column>
								<el-table-column prop="coin" :label="$t('czTxt2')">
									<template slot-scope="scope">
										<span>{{scope.row.coin.unit}}</span>
									</template>
								</el-table-column>
								<el-table-column prop="createTime" :label="$t('apith2')">
								</el-table-column>
								<el-table-column prop="operation" :label="$t('th5')">
									<template slot-scope="scope">
										<el-button  type="primary" class="btn" @click="edit(scope.row)">{{$t('acbtn3')}}
										</el-button>
										<el-button  type="primary" class="btn" v-if="scope.row.status == 1"
											@click="onShelves(scope.row.id)">
											{{$t('adbtn2')}}
										</el-button>
										<el-button  type="primary" class="btn" v-if="scope.row.status == 0"
											@click="offShelves(scope.row.id)">
											{{$t('adbtn3')}}
										</el-button>
										<el-button  type="primary" class="btn" @click="delClick(scope.row)">
											{{$t('delete')}}
										</el-button>
									</template>
								</el-table-column>
								<template slot="empty" class="exte_empty">
									<img src="../../assets/photo/exte1.png" alt="">
									<p>{{$t('czEmpty')}}</p>
								</template>
							</el-table>
							<el-dialog :visible.sync="dialogVisible" :show-close="false" width="380px"
								custom-class="custom-dialog custom-dialog-tip">
								<div slot="title" class="dialog-title"><b>{{$t('czTxt9')}}</b></div>
								<div class="dialog-content">
									<div class="dialog-text">
										{{$t('adtip')}}？
									</div>
								</div>
								<div slot="footer" class="dialog-footer">
									<el-row :gutter="14">
										<el-col :span="12">
											<el-button @click="dialogVisible = false">{{$t('cancel')}}
											</el-button>
										</el-col>
										<el-col :span="12">
											<el-button type="primary" @click="confirm">{{$t('confirm2')}}
											</el-button>
										</el-col>
									</el-row>
								</div>
							</el-dialog>
						</div>
						<div class="exte-box">
							<el-pagination layout="prev, pager, next" @current-change="handleCurrentChange"
								:current-page.sync="currentPage" :total="total" :background="true"></el-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
		<Foot />
	</div>
</template>

<script>
	import {
		advertiseAll,
		advertiseDel,
		advertiseOn,
		advertiseOff
	} from '@/api/api/otc'
	import OrderNav from '@/components/OrderNav.vue'
	import Head from '@/components/Head.vue'
	import Foot from '@/components/Foot.vue'
	export default {
		name: 'Home',
		components: {
			Head,
			OrderNav,
			Foot
		},
		data() {
			return {
				tableData: [],
				currentPage: 1,
				pageSize: 10,
				total: 10,
				dialogVisible: false,
				id: null
			};
		},
		mounted() {
			this.getList()
		},
		methods: {
			getList() {
				advertiseAll({
					pageNo: this.currentPage,
					pageSize: this.pageSize
				}).then(res => {
					if (res.code == 0) {
						this.tableData = res.data.content
						this.pageSize = res.data.size
						this.total = res.data.totalElements
					} else {
						this.$message.error(this.$t('loginFirst'));
					}
				})
			},
			handleCurrentChange(val) {
				this.currentPage = val
				this.getList()
			},
			edit(row) {
				if(row.status == 1){
					this.$router.push({
						path: "/editAd",
						query: {
							id: row.id
						}
					})
				}else{
					this.$message.error(this.$t('shelves2'));
				}
			},
			delClick(row) {
				if(row.status == 1){
					this.id = row.id
					this.dialogVisible = true
				}else{
					this.$message.error(this.$t('shelves'));
				}
			},
			confirm() {
				advertiseDel({
					id: this.id
				}).then(res => {
					if (res.code == 0) {
						this.$message({
							message: res.message,
							type: 'success'
						});
						this.getList()
						this.dialogVisible = false
					}else{
						this.$message({
							message: res.message,
							type: 'error'
						});
					}
				})
			},
			onShelves(id) {
				advertiseOn({
					id: id
				}).then(res => {
					if (res.code == 0) {
						this.$message({
							message: res.message,
							type: 'success'
						});
						this.getList()
					} else {
						this.$message({
							message: res.message,
							type: 'error'
						});
					}
				})
			},
			offShelves(id) {
				advertiseOff({
					id: id
				}).then(res => {
					if (res.code == 0) {
						this.$message({
							message: res.message,
							type: 'success'
						});
						this.getList()
					} else {
						this.$message({
							message: res.message,
							type: 'error'
						});
					}
				})
			},
			pushAd() {
				this.$router.push({
					path: "/createAd"
				})
			}
		}
	}
</script>
